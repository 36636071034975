.IconList_container {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -20px;
  border-radius: 15px 15px 0px 0px;
  padding-top: 10px;
  background-color: #f7f7f7;
}
.IconsList_item {
  text-align: center;
}
.IconsList_itemIcon {
  font-size: 25px;
}
.IconsList_itemIconTitle {
  margin-top: -13px;
  font-size: 12px;
  text-transform: uppercase;
}
