.CircleCategories_container {
  position: relative;
  width: 100%;
  padding-top: 0px;
  background-color: #f7f7f7;
  z-index: 999999999;
}
.CircleCategories_title {
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 50px;
  padding: 0px;
  background-color: #000000b6;
}
.CircleCategories_titleText {
  color: #fff;
  font-weight: 900;
}
.CircleCategories_itemIcon {
  font-size: 25px;
}
.CircleCategories_itemIconTitle {
  margin-top: -13px;
  font-size: 12px;
  text-transform: uppercase;
}
.CircleCategories_cardTitleBlockTitle {
  font-size: 16px;
  font-weight: 800;
}
.CircleCategories_item {
  text-align: center;
  margin-bottom: -20px;
}
.CircleCategories_cardTitleBlockDates {
  font-size: 12px;
  font-weight: 700;
}
.CircleCategories_cardTitleBlockDesc {
  font-size: 12px;
}
.CircleCategories_categoryList {
  margin-left: -15px;
}
.CircleCategories_categoryListTitle {
  font-weight: 600;
  font-size: 30px;
  padding-left: 18px;
}
.CircleCategories_titleSubText {
  color: #fff;
  font-size: small;
}
.CircleCategories_scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 80px;
  margin-right: -30px;
  margin-left: -6px;
  height: 200px;
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.CircleCategories_scrolling-wrapper::-webkit-scrollbar {
  display: none;
}


.CircleCategories_card {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 180px;
  border: #000000 solid 0px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.21);
  margin: 3px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
}
.CircleCategories_cardTitleBlock {
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 120px;
  padding-left: 10px;
  border-radius: 4px;
  background: linear-gradient(#00000000, #000000);
}
.CircleCategories_cardTitleContent {
  height: 40px;
  align-self: flex-end;
}
