.ProfileDetails_container {
  background-color: #f7f7f7;
  border-top: #ccc 0px solid;
  margin: 20px;
}
.Profile_description {
  text-align: left;
  padding: 0px 15px 0px 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  border-top: #ccc 3px solid;
}
.ProfileDetails_icon {
  text-align: center;
  font-size: 25px;
}
.ProfileDetails_icon_title {
  margin-top: -13px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}
.ProfileDetails_date_container {
  text-align: center;
  border: 3px #ccc solid;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 8px;
}
.ProfileDetails_date {
  padding-top: 4px;
  font-size: 20px;
  font-weight: 600;
}
.ProfileDetails_time {
  font-size: small;
}
.ProfileDetails_label {
  font-size: small;
}
.Detail_Input {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 4px;
  background-color: white;
}
