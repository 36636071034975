.HomeCategories_container {
  position: relative;
  width: 100%;
  margin-top: 10px;
  background-color: #f7f7f7;
  z-index: 999999;
}
.HomeCategories_title {
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 50px;
  padding: 0px;
  background-color: #000000b6;
}
.HomeCategories_titleText {
  color: #fff;
  font-weight: 900;
}
.HomeCategories_itemIcon {
  font-size: 25px;
}
.HomeCategories_itemIconTitle {
  margin-top: -13px;
  font-size: 12px;
  text-transform: uppercase;
}
.HomeCategories_cardTitleBlockTitle {
  font-size: 16px;
  font-weight: 800;
}
.HomeCategories_item {
  text-align: center;
  margin-bottom: -20px;
}
.HomeCategories_cardTitleBlockDates {
  font-size: 12px;
  font-weight: 700;
}
.HomeCategories_cardTitleBlockDesc {
  font-size: 12px;
}
.HomeCategories_categoryList {
  margin-left: -15px;
}
.HomeCategories_categoryListTitle {
  font-weight: 600;
  font-size: 30px;
  padding-left: 18px;
}
.HomeCategories_titleSubText {
  color: #fff;
  font-size: small;
}
.HomeCategories_scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 80px;
  margin-right: -30px;
  height: 215px;

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.HomeCategories_scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.HomeCategories_card {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 180px;
  border: #000000 solid 0px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.21);
  margin: 3px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
}
.HomeCategories_cardTitleBlock {
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 120px;
  padding-left: 10px;
  border-radius: 4px;
  background: linear-gradient(#00000000, #000000);
}
.HomeCategories_cardTitleContent {
  height: 40px;
  align-self: flex-end;
}
