.NewForm_input {
  padding: 6px;
  border: 1px #ccc solid;
  border-radius: 5px;
}
.NewForm_input_container {
  padding-top: 10px;
}
.picker-columns,
.picker-toolbar,
.picker-md .picker-wrapper,
.picker-md .picker-toolbar {
  background: whitesmoke;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.picker-md .picker-prefix,
.picker-md .picker-suffix,
.picker-md .picker-opt.picker-opt-selected {
  color: #fff;
}
.picker-md .picker-opt {
  color: #ccc;
}

.picker-md .picker-button,
.picker-md .picker-button.activated {
  color: #fff;
}
.picker-wrapper.sc-ion-picker-ios {
  background-color: white;
}
