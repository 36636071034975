.Card1_cardTitleBlockTitle {
  width: 210px;
  font-size: 14px;
  white-space: normal !important;
  font-weight: 500;
  padding-left: 5px;
  align-self: flex-end;
}
.Card1_cardTitleBlockDates {
  font-size: 11px;
  font-weight: 400;
  padding-left: 5px;
}
.Card1_cardTitleBlockDesc {
  font-size: 12px;
}
.Card1_cardTitleContent {
  display: flex;
  margin-bottom: 8px;
}
.Card1_card {
  position: relative;
  display: inline-block;
  height: 160px;
  border: #000000 solid 0px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.21);
  margin: 10px;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 14px 34px -16px #000000;
}
.Card1_cardTitleBlock {
  width: 220px;
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  padding-left: 5px;
  border-radius: 15px;
  background: linear-gradient(#00000000, #000000);
}
