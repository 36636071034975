.ProfileHeroImage_heroImageContainer {
  position: relative;
  width: 100%;
  height: 50vh;
  z-index: -1;
  border-radius: 0px 0px 25px 25px;

}
.ProfileHeroImage_heroImageContainerDiv {
  position: relative;
  height: 50vh;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  z-index: 1;
  box-shadow: 0px 14px 34px -4px #00000027,
    -18px -125px 50px -74px rgb(0, 0, 0) inset;
  border-radius: 0px 0px 25px 25px;
}

.ProfileHeroImage_titleText {
  padding-top: calc(50vh -  45px);
  padding-left: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.ProfileHeroImage_titleDate {
  position: absolute;
  top: 70px;
  right: 10px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.863);
  border-radius: 8px;
  border: 4px solid rgb(56, 56, 56);
  box-shadow: 0px 0px 4px 0px #00000069,
    0px 0px 10px 4px rgba(0, 0, 0, 0.123) inset;
}
