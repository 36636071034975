.ProfileIconsList_container {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -20px;
  margin-top: 0px;
  background-color: #f7f7f7;
  z-index: -2;
}
.ProfileIconsList_item {
  text-align: center;
}
.ProfileIconsList_itemIcon {
  font-size: 25px;
}
.ProfileIconsList_itemIconTitle {
  margin-top: -13px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}
