.Card3_cardTitleBlockTitle {
  width: 210px;
  white-space: wrap;
  word-wrap: break-word;
  font-size: 14px;
  overflow-wrap: normal;
  font-weight: 500;
  overflow: hidden;
}
.Card3_cardTitleBlockDates {
  font-size: 11px;
  font-weight: 400;
}
.Card3_cardTitleBlockDesc {
  font-size: 12px;
}
.Card3_cardTitleContent {
  height: 40px;
  align-self: flex-end;
}
.Card3_card {
  position: relative;
  display: inline-block;
  overflow-wrap: break-word;
  width: 220px;
  height: 160px;
  border: #000000 solid 0px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.21);
  margin: 10px;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 14px 34px -16px #000000;
}
.Card3_cardTitleBlock {
  overflow-wrap: normal;
  width: 220px;
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  padding-left: 5px;
  border-radius: 15px;
  background: linear-gradient(#00000000, #000000);
  white-space: wrap;
  word-wrap: break-word;
  font-weight: 500;
}
