.ListHeader_container {
  position: relative;
  color: white;
  font-size: 23px;
  text-align: left;
  font-weight: 600;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 0px 14px 34px -4px #00000027,
    -18px -125px 50px -74px rgba(0, 0, 0, 0.726) inset;
}
.ListHeader_text {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
