.Card2_cardTitleBlockTitle {
  font-size: 12px;
  font-weight: 600;
}
.Card2_cardTitleBlockDates {
  font-size: 11px;
  font-weight: 400;
}
.Card2_cardTitleBlockDesc {
  font-size: 11px;
}
.Card2_cardTitleContent {
  height: 40px;
}
.Card2_card {
  border-radius: 10px 10px 10px 10px;
  margin: 10px;
  width: calc(100% - 20px);
  height: 130px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.075);
  overflow: hidden;
}
.Card2_cardTitleBlock {
  color: #fff;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(#00000000, #000000);
}
.Card2_imageblock {
  margin-top: -5px;
  margin-left: -5px;
  height: 130px;
  border-radius: 10px 0px 0px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(22, 22, 22);
}
.Card2_success {
  width: 100%;
  background-color: #2dd36f;
  position: absolute;
  font-size: 12px;
  bottom: 0;
  right: 0;
  text-align: center;
  padding: 2px;
  font-weight: 600;
  border-top: solid 0px rgb(87, 87, 87);
}
