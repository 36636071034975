.HomeText_container {
  text-align: center;
  background-color: #f7f7f7;
}
.HomeText_logo {
  height: 80px;
  width: 80px;
}

.HomeText_line1 {
  font-size: 10px;
}
.HomeText_line2 {
  font-size: 10px;
}
