.SocialIcons_container {
  position: relative;
  padding-top: 30px;
  width: 100%;
  height: 100px;

  background-color: #f7f7f7;
}

.SocialIcons_icon {
  font-size: 25px;
}
