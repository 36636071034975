.ListFooter_container {
  margin-bottom: -20px;
  border-radius: 25px 25px 0px 0px;
  padding-top: 10px;
  height: 130px;
  margin-top: -30px;
  box-shadow: 0px 14px 34px -4px #000000, inset 0px 14px 34px -4px #0000000e;
  background-color: rgb(255, 255, 255);
  z-index: 1333;
}
.ListFooter_toolbar {
  background-color: rgba(199, 199, 199, 0);
  margin-left: 10px;
  width: calc(100% - 20px);
}
.ListFooter_title {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
}
.toolbar-background {
  background-color: rgba(255, 255, 255, 0);
}
.ListFooter_searchbox {
  border: rgb(175, 175, 175) 1px solid;
  margin: 10px;
  width: calc(100% - 20px);
  padding-left: 10px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: inset 0px 14px 34px -4px #00000013;
}
