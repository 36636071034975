.item-content {
  background-color: #ffffff00;
}
.item .item-content {
  background-color: transparent !important;
}
.Maps_img {
  width: 100%;
}
.Maps_imgCol {
  overflow: hidden;
  height: 50;
}
.Maps_modal {
}
.Maps_modal_container {
}
.Maps_modal_close {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
}
