.ProfileAction_col {
  display: table;
  text-align: center;
  vertical-align: middle;
  padding-top: 12px;
  width: 100%;
}
.ProfileAction_button {
  height: 40px !important;
  width: 100%;
  border: dashed 3px #ccc;
  border-radius: 8px;
  padding-top: 8px;
  display: table-cell;
  font-weight: 600;
  font-size: small;
  vertical-align: middle;
}
.ProfileAction_button_success {
  height: 40px !important;
  border: solid 3px green;
  background-color: rgb(226, 226, 226);
  color: #000;
  border-radius: 8px;
  width: 100%;
  padding-top: 8px;
  font-weight: 900;
  font-size: small;
}
.ProfileAction_button_loading {
  height: 40px !important;
  width: 100%;
  border: dashed 3px #ccc;
  color: #ccc;
  border-radius: 8px;
  display: table-cell;
  font-weight: 600;
  font-size: small;
  vertical-align: middle;
}
