.HeroImage_heroImageContainer {
  position: relative;
  width: 100%;
  height: 300px;
  z-index: -1;
}
.HeroImage_heroImageContainerDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 14px 34px -16px #000000;
  z-index: -1;
}
.HeroImage_title {
  text-align: left;
  position: absolute;
  bottom: -10px !important;
  left: 0 !important;
  width: 100%;
  height: 95px;
  padding: 8px 8px 8px 18px;
  background: linear-gradient(#00000000, #00000094, #000000a1, #000000);
  /* border-radius: 0px 0px 25px 25px; */
  z-index: 1;
}
.HeroImage_titleText {
  margin-top: 25px;
  color: #fff;
  font-weight: 900;
}
.HeroImage_titleSubText {
  color: #fff;
  font-size: small;
}
